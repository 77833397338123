// Misc.
$misc: (
  z-index-base: 10000
);

// Duration.
$duration: (
  navPanel: 0.5s
);

// Size.
$size: (
  navPanel: 275px
);

// Font.
$font: (
);

// Palette.
$palette: (
);
